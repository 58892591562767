/**
 * The reason for having index.ts and bootstrap is because of
 * Webpack error Shared module is not available for eager consumption:
 * If you are trying to share modules between Module Federation microfrontends,
 * you might face this strange error.
 *
 * The reason for this error is that react, react-dom and other dependencies are
 * shared modules inside webpack.config.ts.
 *
 * The solution is to have a bootstrap file this imported in the index.ts
 * This makes sure webpack to load the dependencies before loading bootstrap.js.
 * References: https://github.com/manfredsteyer/module-federation-plugin-example-nx/issues/3
 * https://webpack.js.org/concepts/module-federation/#uncaught-error-shared-module-is-not-available-for-eager-consumption
 */
import('./ChatTest');
